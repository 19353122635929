$borderRadius: 10px;
$spacer: 1rem;
$primary: #C4151C;
$text: #fff;
$linkHeight: $spacer * 3.5;
$timing: 250ms;
$transition: $timing ease all;
$linkWidth: 10rem;



@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
body{
  background-color: black;
  background-position:center;
  font-family: 'Open Sans', sans-serif;
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header{
    $ref: &;
    position: sticky;
    font-size: 100%;
    background: rgba(0, 0, 0, 0.9);  
    color: $text;
    padding: 0 $spacer*2;
    box-shadow: 0 0 40% rgba(0,0,0,0.03);
    height: 6rem;
    display: flex;
    align-items:left;
    gap: 3rem;

  }


  .logo_image {
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .navbar{
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  &__menu{
    position: relative;
    display:flex;
  }
  &__link{
    
    position:relative;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    align-items: center;
    justify-content: center;
    height: $linkHeight;
    width: $linkWidth;
    color: $text;
    transition: $transition;
    text-decoration: none;
    width: $linkWidth;
    text-transform: uppercase;
    transition: 500ms ease all;
    svg{
      transition: 500ms ease all;
    }
    &:hover{
      svg{
        stroke: $primary;
      }
    }
    .navbar:not(:hover) &:focus,
    &:hover{
      span{
        opacity: 1;
        transform: translate(0);
      }
    }
  }
  &__item{
    &:last-child{
      &:before {
        content: '';
        position: absolute;
        left: -8rem;
        margin-left: $linkWidth/2;
        bottom: -10%;
        height: 17%;
        width: 0.2%;
        background: $primary;
        $speedlineColor: rgba(#fff,0.2);
        $speedGap: 3rem;
        $shadow : 0 -0.5rem $primary,0 -0.5rem $primary, 0 0 $speedGap 0.5rem $primary,2rem 0 0 $speedlineColor, -$speedGap 0 0 $speedlineColor;
        @for $i from 2 to 120{
          $shadow: $shadow + #{","}+ $i*$speedGap 0 0 $speedlineColor;
          $shadow: $shadow + #{","}+ $i*-$speedGap 0 0 $speedlineColor;
        }
        box-shadow: $shadow;
        transition: 500ms ease all;
      }
    }
    
    @for $i from 1 to 12 {
      &:first-child:nth-last-child(#{$i}),
      &:first-child:nth-last-child(#{$i}) ~ li {
        @for $j from 1 to $i {
          &:nth-child(#{$j}):hover {
            ~ li:last-child:before {
              left: (100% / $i) * ($j - 1);
            }
          }
        }
        &:last-child:hover:before {
          left: (100% / $i) * ($i - 1);
        }
      }
    }
    
  }
}






@media only screen and (min-width: 1400px) {
  .container {
    display: none;
  }
}


@media only screen and (max-width: 430px) {
   .navbar {
      display: none;
   }

   .logo_image {
      width: 60%;
      margin: auto;
      margin-top: 27px;
   }

   $items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

/* Basic Styles */

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
}

body {
  font-family: sans-serif;
  background-color: black;
}

a {
  text-decoration: none;
}

.container {
  position: relative;
  margin: 0% ;
  width: 100%;
  height: 534%;
  overflow: hidden;
  margin-top: -23%;
}

/* Toggle Functionality */

#toggle {
  display: none; //hides the checkbox
}


// Styles for the 'open' state, if the checkbox is checked
#toggle:checked {
  //Any element you need to change the style if menu is open goes here, using the sibling selector (~)
  & ~ .toggle-container .button-toggle {
    box-shadow: 0 0 0 400px rgba(255, 255, 255, 0.1), inset 0 0 0 20px rgba(255, 253, 253, 0.1);

    &:before {
      transform: translateY(-50%) rotate(45deg) scale(1);
    }

    &:after {
      transform: translateY(-50%) rotate(-45deg) scale(1);
    }
  }

  // Open Nav
  & ~ .nav {
    margin-bottom: 70px;
    pointer-events: auto;
    transform: translate(50px, 50px);

    // Restoring nav items from 'lines' in the menu icon
    .nav-item {
      color: #b41500;
      letter-spacing: 0;
      height: 350%;
      line-height: 350%;
      margin-top: 0%;
      opacity: 1;
      transform: scaleY(1);
      transition: $transition-duration, opacity 0.1s;


      // Setting delays for the nav items in open transition
      @for $i from 1 through $items {
        &:nth-child(#{$i}) {
          $delay: ($items - $i) * $transition-delay;
          transition-delay: $delay;
          &:before {
            transition-delay: $delay;
          }
        }
      }

      // Hide the lines
      &:before {
        opacity: 0;
      }
    }
  }

  & ~ .dummy-content {
    padding-top: 0%;

    &:before {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}

/* Toggle Button */

.button-toggle {
  position: absolute;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 25px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  transition: $transition-duration + 0.1;

  // Shadow on Hover
  &:hover {
   box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.1), inset 0 0 0 20px rgba(255, 255, 255, 0.1);
  }

  // Making the 'X' icon using `:before` and `:after` pseudo-elements
  // Initially hidden beause of `scale(0)` transformation

  &:before, &:after {
    position: absolute;
    content: '';
    top: 0%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #b41500;
    border-radius: 5px;
    transition: $transition-duration;
  }

  &:before {
    transform: translateY(-50%) rotate(45deg) scale(0);
  }

  &:after {
    transform: translateY(50%) rotate(-45deg) scale(0);
  }
}

/* Menu */

.nav {
  display: inline-block;
  margin: 25px 25px 20px;
  pointer-events: none;
  transition: $transition-duration;
}

// Showing nav items as lines, making up the hamburger menu icon
.nav-item {
  position: relative;
  display: inline-block;
  float: left;
  clear: both;
  color: transparent;
  font-size: 14px;
  letter-spacing: -6.2px;
  height: 7px;
  line-height: 7px;
  text-transform: uppercase;
  white-space: nowrap;
  transform: scaleY(0.2);
  transition: $transition-duration, opacity 1s;

  //Set delays for the nav items in close transition
  @for $i from 1 through $items {
    &:nth-child(#{$i}) {
      $delay: ($i - 1) * $transition-delay;
      transition-delay: $delay;
      &:before {
        transition-delay: $delay;
      }
    }
  }

  // Adjusting width for first line
  &:nth-child(1) {
    letter-spacing: -8px;
  }

  // Adjusting width for second line
  &:nth-child(2) {
    letter-spacing: -7px;
  }

  // Adjusting the fourth element onwards
  &:nth-child(n + 4) {
    letter-spacing: -8px;
    margin-top: -7px;
    opacity: 0;
  }

  //getting lines for the hamburger menu icon
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #b41500;
    transform: translateY(-50%) scaleY(5);
    transition: $transition-duration;
  }
}
 }


 @media screen and (min-width:1280px) and (max-width: 1400px) {
  .navbar {
    display: none;
 }

 .logo_image {
    width: 60%;
    margin: auto;
    margin-top: 27px;
 }

 $items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

/* Basic Styles */

* {
box-sizing: border-box;
}

html, body {
margin: 0;
}

body {
font-family: sans-serif;
background-color: black;
}

a {
text-decoration: none;
}

.container {
position: relative;
margin: 0% ;
width: 100%;
height: 53%;
overflow: hidden;
margin-top: -6%;
}

/* Toggle Functionality */

#toggle {
display: none; //hides the checkbox
}


// Styles for the 'open' state, if the checkbox is checked
#toggle:checked {
//Any element you need to change the style if menu is open goes here, using the sibling selector (~)
& ~ .toggle-container .button-toggle {
  box-shadow: 0 0 0 400px rgba(255, 255, 255, 0.1), inset 0 0 0 20px rgba(255, 253, 253, 0.1);

  &:before {
    transform: translateY(-50%) rotate(45deg) scale(1);
  }

  &:after {
    transform: translateY(-50%) rotate(-45deg) scale(1);
  }
}

// Open Nav
& ~ .nav {
  margin-bottom: 70px;
  pointer-events: auto;
  transform: translate(50px, 50px);

  // Restoring nav items from 'lines' in the menu icon
  .nav-item {
    color: #b41500;
    letter-spacing: 0;
    height: 35%;
    line-height: 350%;
    margin-top: 0%;
    opacity: 1;
    transform: scaleY(1);
    transition: $transition-duration, opacity 0.1s;


    // Setting delays for the nav items in open transition
    @for $i from 1 through $items {
      &:nth-child(#{$i}) {
        $delay: ($items - $i) * $transition-delay;
        transition-delay: $delay;
        &:before {
          transition-delay: $delay;
        }
      }
    }

    // Hide the lines
    &:before {
      opacity: 0;
    }
  }
}
}

/* Toggle Button */

.button-toggle {
position: absolute;
display: inline-block;
width: 20px;
height: 20px;
margin: 25px;
background-color: transparent;
border: none;
cursor: pointer;
border-radius: 10%;
transition: $transition-duration + 0.1;

// Shadow on Hover
&:hover {
 box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.1), inset 0 0 0 20px rgba(255, 255, 255, 0.1);
}

// Making the 'X' icon using `:before` and `:after` pseudo-elements
// Initially hidden beause of `scale(0)` transformation

&:before, &:after {
  position: absolute;
  content: '';
  top: 0%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #b41500;
  border-radius: 5px;
  transition: $transition-duration;
}

&:before {
  transform: translateY(-50%) rotate(45deg) scale(0);
}

&:after {
  transform: translateY(50%) rotate(-45deg) scale(0);
}
}

/* Menu */

.nav {
display: inline-block;
margin: 25px 25px 20px;
pointer-events: none;
transition: $transition-duration;
}

// Showing nav items as lines, making up the hamburger menu icon
.nav-item {
position: relative;
display: inline-block;
float: left;
clear: both;
color: transparent;
font-size: 14px;
letter-spacing: -6.2px;
height: 7px;
line-height: 7px;
text-transform: uppercase;
white-space: nowrap;
transform: scaleY(0.2);
transition: $transition-duration, opacity 1s;

//Set delays for the nav items in close transition
@for $i from 1 through $items {
  &:nth-child(#{$i}) {
    $delay: ($i - 1) * $transition-delay;
    transition-delay: $delay;
    &:before {
      transition-delay: $delay;
    }
  }
}

// Adjusting width for first line
&:nth-child(1) {
  letter-spacing: -8px;
}

// Adjusting width for second line
&:nth-child(2) {
  letter-spacing: -7px;
}

// Adjusting the fourth element onwards
&:nth-child(n + 4) {
  letter-spacing: -8px;
  margin-top: -1px;
  opacity: 0;
}

//getting lines for the hamburger menu icon
&:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #b41500;
  transform: translateY(-50%) scaleY(5);
  transition: $transition-duration;
}
}
}


 @media screen and (min-width:431px) and (max-width:1279px){
  .navbar {
    display: none;
 }

 .logo_image {
    width: 60%;
    margin: auto;
    margin-top: 27px;
 }

 $items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

/* Basic Styles */

* {
box-sizing: border-box;
}

html, body {
margin: 0;
}

body {
font-family: sans-serif;
background-color: black;
}

a {
text-decoration: none;
}

.container {
position: relative;
margin: 0% ;
width: 100%;
height: 53%;
overflow: hidden;
margin-top: -8%;
}

/* Toggle Functionality */

#toggle {
display: none; //hides the checkbox
}


// Styles for the 'open' state, if the checkbox is checked
#toggle:checked {
//Any element you need to change the style if menu is open goes here, using the sibling selector (~)
& ~ .toggle-container .button-toggle {
  box-shadow: 0 0 0 400px rgba(255, 255, 255, 0.1), inset 0 0 0 20px rgba(255, 253, 253, 0.1);

  &:before {
    transform: translateY(-50px) rotate(45deg) scale(1);
  }

  &:after {
    transform: translateY(-50px) rotate(-45deg) scale(1);
  }
}

// Open Nav
& ~ .nav {
  margin-bottom: 40px;
  pointer-events: auto;
  transform: translate(50px, 50px);

  // Restoring nav items from 'lines' in the menu icon
  .nav-item {
    color: #b41500;
    letter-spacing: 0;
    height: 350%;
    line-height: 350%;
    margin-top: 0%;
    opacity: 1;
    transform: scaleY(1);
    transition: $transition-duration, opacity 0.1s;


    // Setting delays for the nav items in open transition
    @for $i from 1 through $items {
      &:nth-child(#{$i}) {
        $delay: ($items - $i) * $transition-delay;
        transition-delay: $delay;
        &:before {
          transition-delay: $delay;
        }
      }
    }

    // Hide the lines
    &:before {
      opacity: 0;
    }
  }
}
}

/* Toggle Button */

.button-toggle {
position: absolute;
display: inline-block;
width: 20px;
height: 20px;
margin: 25px;
background-color: transparent;
border: none;
cursor: pointer;
border-radius: 100%;
transition: $transition-duration + 0.1;

// Shadow on Hover
&:hover {
 box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.1), inset 0 0 0 20px rgba(255, 255, 255, 0.1);
}

// Making the 'X' icon using `:before` and `:after` pseudo-elements
// Initially hidden beause of `scale(0)` transformation

&:before, &:after {
  position: absolute;
  content: '';
  top: 0%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #b41500;
  border-radius: 5px;
  transition: $transition-duration;
}

&:before {
  transform: translateY(-50%) rotate(45deg) scale(0);
}

&:after {
  transform: translateY(50%) rotate(-45deg) scale(0);
}
}

/* Menu */

.nav {
display: inline-block;
margin: 25px 25px 20px;
pointer-events: none;
transition: $transition-duration;
}

// Showing nav items as lines, making up the hamburger menu icon
.nav-item {
position: relative;
display: inline-block;
float: left;
clear: both;
color: transparent;
font-size: 14px;
letter-spacing: -6.2px;
height: 7px;
line-height: 7px;
text-transform: uppercase;
white-space: nowrap;
transform: scaleY(0.2);
transition: $transition-duration, opacity 1s;

//Set delays for the nav items in close transition
@for $i from 1 through $items {
  &:nth-child(#{$i}) {
    $delay: ($i - 1) * $transition-delay;
    transition-delay: $delay;
    &:before {
      transition-delay: $delay;
    }
  }
}

// Adjusting width for first line
&:nth-child(1) {
  letter-spacing: -8px;
}

// Adjusting width for second line
&:nth-child(2) {
  letter-spacing: -7px;
}

// Adjusting the fourth element onwards
&:nth-child(n + 4) {
  letter-spacing: -8px;
  margin-top: -7px;
  opacity: 0;
}

//getting lines for the hamburger menu icon
&:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #b41500;
  transform: translateY(-50%) scaleY(5);
  transition: $transition-duration;
}
}
 }
