.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.4);
}

.modal-content {
    background-color: #000000;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}