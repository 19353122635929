.temp-container {
    position: relative;
    width: 100%;
    height: 70vh; /* Adjust as needed */
    overflow: hidden;
  }
  
  .background-gif {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the GIF covers the entire container */
    z-index: 0; /* Place the GIF behind other content */
    opacity: 0.5;
  }
  
  .construction-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }