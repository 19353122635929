/* VideoPlayer.css */

/* Container for the entire VideoPlayer component */
.video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust as needed */
    background-color: #000; /* Black background */
  }

  .series-info {
    display: flex;
    align-items: center; /* Vertically center the logo and details */
  }
  
  .episode-details {
    flex: 1; /* Allow the details to take up remaining space */
  }
  
  /* Title styling */
  h2 {
    font-size: 36px;
    font-weight: bold;
    color: #fff; /* White color */
    margin-bottom: 20px;
  }
  
  /* Description styling */
  p {
    font-size: 18px;
    color: #fff; /* White color */
    max-width: 800px; /* Limit description width */
    text-align: left;
    margin-bottom: 40px;
  }
  
  /* Video iframe styling */
  iframe {
    max-width: 70%;
    height: 600px;
    max-width: 100%;
    border: none; /* Remove iframe border */
    display: block; /* Center horizontally */
    margin: auto; /* Center vertically */
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .prev-button,
  .next-button,
  .invisible-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff; /* White text */
    background-color: #ff0000; /* Red background */
    border: none;
    border-radius: 20px; /* Rounded corners */
    cursor: pointer;
  }

  .next-button {
    margin-right: 100px;
  }

  .prev-button {
    margin-left: 100px;
  }
  
  .invisible-button {
    visibility: hidden;
  }

  .seriesLogo {
    margin-top: 20px;
    margin-right: 70px;
    margin-left: 70px;
    margin-bottom: 50px;
    max-height: 170px;
    max-width: 450px;
  }

  @media screen and (min-width:375px) and (max-width:1279px){
    .series-info {
      display: grid;
      align-items: center; /* Vertically center the logo and details */
    }
    
    .episode-details {

    }

    h2 {
      text-align: center;
      margin-left: -50px;
      font-size: 100%;
      font-weight: bold;
      color: #fff; /* White color */
      margin-bottom: 20px;
    }
    
    /* Description styling */
    p {
      font-size: 18px;
      color: #fff; /* White color */
      max-width: 800px; /* Limit description width */
      text-align: left;
      margin-bottom: 40px;
    }

    iframe {
      max-width: 70%;
      max-height: 400px;
      border: none; /* Remove iframe border */
      display: block; /* Center horizontally */
      margin: auto; /* Center vertically */
    }

  }

  @media screen and (min-width:1280px) and (max-width: 1400px) {

  }

