.background-video {
    position: relative;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    margin-top: 7%;
    z-index: -1; /* Ensure the video is behind other content */
  }
  
  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    opacity: 50%;
  }