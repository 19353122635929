.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "Twitter Facebook"
    "TikTok Patreon";
  height: 100vh;
  width: 100vw;
  margin-bottom: 25%;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease-in-out;
}

.grid-item:hover {
  transform: scale(1.1);
}

.grid-item img {
  width: 50%;
  height: auto;
  padding: 10px;
}

.grid-item p {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.grid-item:nth-child(1) { grid-area: Twitter; }
.grid-item:nth-child(2) { grid-area: Facebook; }
.grid-item:nth-child(3) { grid-area: TikTok; }
.grid-item:nth-child(4) { grid-area: Patreon; }