.news-article {
    background-color: darkred;
    margin: 20px auto;
    padding: 20px;
    width: 80%;
    cursor: pointer;
    color: white;
    border-radius: 10px; /* Add this line */
}

.news-article:hover {
    opacity: 0.7;
}